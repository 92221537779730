import React from 'react';
import { Dropdown, Button, Menu } from 'antd';

const SupportMenu = ({ helpForwoodUrl, isMobile }) => {
    const onClickNeedHelp = () => {
        window.open(`${helpForwoodUrl}`);
    };

    const menu = (
        <Menu>
            <Menu.Item onClick={onClickNeedHelp}>
                Help
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown
            overlayStyle={{ zIndex: 10000001 }}
            overlay={menu}
            trigger={['click']}
            data-testid="random-test-id"
        >
            <Button
                style={{ marginRight: 20 }} 
                size={isMobile ? 'default' : 'large'}
                shape="circle"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22px"
                    height="20px"
                    viewBox="0 0 24 20"
                    className="support-menu-icon"
                    style={{ transition: 'fill 0.3s ease' }}
                >
                    <path
                        d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"
                        fill="currentColor"
                    />
                </svg>
            </Button>
        </Dropdown>
    );
};

export default SupportMenu;
