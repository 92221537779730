import React from 'react';
import {Button, Layout, Col, Row, Dropdown, Menu, Icon} from 'antd';

import LanguageSelector from '../Containers/LanguageSelector';
import SupportMenu from '../Containers/SupportMenu';
import Text from './Text';
import logo from '../logo.png';

const {Header} = Layout;

export default props => {
  const {
    isMobile,
    showHamburger,
    showNavMenu,
    toggleNavMenu,
    username,
    logout,
  } = props;

  const hamburger = showHamburger ? (
    <Button
      icon={showNavMenu ? 'menu-fold' : 'menu-unfold'}
      onClick={toggleNavMenu}
      style={{marginRight: 20}}
    />
  ) : null;

  return (
    <Header
      style={{
        background: '#fff',
        borderBottom: '1px solid #ccc',
        boxSizing: 'border-box',
        width: '100%',
        margin: 0,
        padding: '0 16px',
      }}>
      <Row gutter={16}>
        <Col span={8}>
          {hamburger}
          <img
            src={logo}
            alt="Forwood Safety"
            style={{maxWidth: '100%', maxHeight: 32}}
          />
        </Col>
        <Col span={16} style={{textAlign: 'right'}}>
          <SupportMenu helpForwoodUrl={'https://help.forwoodsafety.com/'} isMobile={isMobile}/>
          <LanguageSelector/>
          <Dropdown
            trigger={['click']}
            overlay={UserMenu(logout)}
            overlayStyle={{zIndex: 100000}}>
            {isMobile ? (
              <Button size="large" shape="circle">
                <Icon type="user" style={{fontSize: 16}} />
              </Button>
            ) : (
              <Button size="large">
                <Icon type="user" style={{fontSize: 18}} />
                {decodeURIComponent(username)}
              </Button>
            )}
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
};

const UserMenu = logout => (
  <Menu onClick={logout}>
    <Menu.Item key="logout">
      <Text>Logout</Text>
    </Menu.Item>
  </Menu>
);
