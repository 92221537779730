import { connect } from "react-redux";

import Component from "../Components/SupportMenu.js";

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
  });

const mapDispatchToProps = dispatch => {
  return {
    setLanguage(locale) {
      dispatch({ type: "LANGUAGE_SET", locale });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);